.login__container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    max-width: 1120px;
    height: 100vh;
    margin: 0 auto;
}

.login__section {
    width: 100%;
    max-width: 350px;
    margin-right: 32px;
}

.login__form {
    margin-top: 100px;
}

.login__form__header {
    font-size: 32px;
    margin-bottom: 32px;
}
