@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

body {
    font: 400 14px Roboto, sans-serif;
    background: #F0F0F5;
    -webkit-font-smoothing: antialiased;
}

input, button, textarea {
    font: 400 18px Roboto, sans-serif;
}

button {
    cursor: pointer;
}

form input, textarea {
    width: 100%;
    height: 60px;
    color: #333;
    border-radius: 8px;
    border: 1px solid #dcdce6;
    padding: 0 24px;
}

.button {
    width: 100%;
    height: 60px;
    background: #e02041;
    border: 0;
    border-radius: 8px;
    color: #FFF;
    font-weight: 700;
    margin-top: 16px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    line-height: 60px;
    transition: -webkit-filter .2s;
    transition: filter .2s;
    transition: filter .2s, -webkit-filter .2s;

}
.button:hover { 
    -webkit-filter: brightness(90%); 
            filter: brightness(90%);

}

.back-link {
    display: flex;
    align-items: center;
    margin-top: 40px;
    color: #41414B;
    font-size: 18px;
    text-decoration: none;
    font-weight: 500;
    transition: opacity .2s;
}

.back-link:hover {
    opacity: 0.6;

}

.back-link svg {
    margin-right: 8px;
}
.login__container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    max-width: 1120px;
    height: 100vh;
    margin: 0 auto;
}

.login__section {
    width: 100%;
    max-width: 350px;
    margin-right: 32px;
}

.login__form {
    margin-top: 100px;
}

.login__form__header {
    font-size: 32px;
    margin-bottom: 32px;
}

.register__container {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 1120px;
    height: 100vh;
    margin: 0 auto;
}

.register__content {
    display: flex;
    justify-content: space-between;
    align-content: center;

    width: 100%;
    padding: 96px;
    background: #f0f0f5;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    
}

.register__section {
    width: 100%;
    max-width: 380px;   
}

.register__section__header {
    margin: 64px 0 32px;
    font-size: 32px;
}

.register__section__text {
    font-size: 18px;
    color: #737380;
    line-height: 32px;
}

.register__form {
    width: 100%;
    max-width: 450px;
}

.register__form input {
    margin-top: 8px;
}

.register__form__group {
    display: flex;
}
.register__form__group input + input {
    margin-left: 8px;
}
.register-incident__container {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 1120px;
    height: 100vh;
    margin: 0 auto;
}

.register-incident__content {
    display: flex;
    justify-content: space-between;
    align-content: center;

    width: 100%;
    padding: 96px;
    background: #f0f0f5;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    
}

.register-incident__section {
    width: 100%;
    max-width: 380px;   
}

.register-incident__section__header {
    margin: 64px 0 32px;
    font-size: 32px;
}

.register-incident__section__text {
    font-size: 18px;
    color: #737380;
    line-height: 32px;
}

.register-incident__form {
    width: 100%;
    max-width: 450px;
}

.register-incident__form input, textarea {
    margin-top: 8px;
}
.register-incident__form textarea {
    padding-top: 16px;
    resize: none;
}
.register-incident__form__group {
    display: flex;
    justify-content: space-between;
}

.profile__container {
    width: 100%;
    max-width: 1180px;
    padding: 0 30px;
    margin: 32px auto;
}

.profile__header {
    display: flex;
    align-items: center;
}

.profile__header span {
    font-size: 20px;
    margin-left: 24px;
}

.profile__header img { 
    height: 64px;
}

.profile__header a { 
    width: 260px;
    margin-left: auto;
    margin-top: 0;
}

.profile__header button {
    height: 60px;
    width: 60px;
    border-radius: 4px;
    border: 1px solid #dcdce6;
    background: transparent;
    margin-left: 16px;
    transition: border-color .2s;
}

.profile__header button:hover {
    border-color: #999;
}



.profile__content h1 {
    margin-top: 80px;
    margin-bottom: 24px;
}

.profile__content ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    list-style: none;
}

.profile__content ul li {
    background: #FFF;
    padding: 24px;
    border-radius: 8px;
    position: relative;
}

.profile__content ul li button {
    position: absolute;
    right: 24px;
    top: 24px;
    border: 0;
}

.profile__content ul li button:hover { 
    opacity: .8;
}

.profile__content ul li strong {
    display: block;
    margin-bottom: 16px;
    color: #41414D;
}

.profile__content ul li p + strong {
    margin-top: 32px;
}

.profile__content ul li p {
    color: #737380;
    line-height: 21px;
    font-size: 16px;
}
