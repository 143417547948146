.profile__container {
    width: 100%;
    max-width: 1180px;
    padding: 0 30px;
    margin: 32px auto;
}

.profile__header {
    display: flex;
    align-items: center;
}

.profile__header span {
    font-size: 20px;
    margin-left: 24px;
}

.profile__header img { 
    height: 64px;
}

.profile__header a { 
    width: 260px;
    margin-left: auto;
    margin-top: 0;
}

.profile__header button {
    height: 60px;
    width: 60px;
    border-radius: 4px;
    border: 1px solid #dcdce6;
    background: transparent;
    margin-left: 16px;
    transition: border-color .2s;
}

.profile__header button:hover {
    border-color: #999;
}



.profile__content h1 {
    margin-top: 80px;
    margin-bottom: 24px;
}

.profile__content ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    list-style: none;
}

.profile__content ul li {
    background: #FFF;
    padding: 24px;
    border-radius: 8px;
    position: relative;
}

.profile__content ul li button {
    position: absolute;
    right: 24px;
    top: 24px;
    border: 0;
}

.profile__content ul li button:hover { 
    opacity: .8;
}

.profile__content ul li strong {
    display: block;
    margin-bottom: 16px;
    color: #41414D;
}

.profile__content ul li p + strong {
    margin-top: 32px;
}

.profile__content ul li p {
    color: #737380;
    line-height: 21px;
    font-size: 16px;
}