.register-incident__container {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 1120px;
    height: 100vh;
    margin: 0 auto;
}

.register-incident__content {
    display: flex;
    justify-content: space-between;
    align-content: center;

    width: 100%;
    padding: 96px;
    background: #f0f0f5;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    
}

.register-incident__section {
    width: 100%;
    max-width: 380px;   
}

.register-incident__section__header {
    margin: 64px 0 32px;
    font-size: 32px;
}

.register-incident__section__text {
    font-size: 18px;
    color: #737380;
    line-height: 32px;
}

.register-incident__form {
    width: 100%;
    max-width: 450px;
}

.register-incident__form input, textarea {
    margin-top: 8px;
}
.register-incident__form textarea {
    padding-top: 16px;
    resize: none;
}
.register-incident__form__group {
    display: flex;
    justify-content: space-between;
}
